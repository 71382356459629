export const Epooja = [
    {
      "id": 0,
      "img": "../../../assets/images/bhaatPooja.jpeg')",
      "title": "Rinmukti Abhishek Pooja",
      "date":new Date('2024-11-25'),
      "short_desc": "Seek relief from debts and financial difficulties.",
      "long_desc": "Rinmukti Abhishek Poojan is a powerful ritual dedicated to Lord Shiva, performed to seek relief from debts and financial difficulties. The ritual involves offering gram dal (चने की दाल) and chanting mantras for Lord Shiva's blessings.",
      "place": "Live from Rinmukteshwar Mahadev (Ujjain)",
      "puja_details": "Rinmukti Abhishek Poojan involves offering gram dal to Lord Shiva and chanting mantras to seek his blessings for debt relief and financial prosperity.",
      "benefit_of_puja": {
        "Debt Relief": "Helps in solving financial difficulties and debts.",
        "Prosperity": "Invokes blessings from Lord Ganesh and Goddess Lakshmi for wealth and prosperity.",
        "Peace of Mind": "Blesses devotees with a peaceful mind and positive spirit."
      },
      "about_img": "",
      "about_puja": "At the Rinmukteshwar Mahadev Temple, priests conduct a special ritual known as 'Peeli Pooja.' It is believed that performing this pooja helps devotees attain relief from debts swiftly. During the Peeli Pooja, all offerings presented to Lord Mahadev are yellow, symbolizing prosperity and purity. These offerings include items such as yellow clothes, gram dal, lentils, turmeric knots, yellow flowers, and a small amount of jaggery, which are placed on the sacred Shivlinga along with a personal wish or prayer. \n\nThe ritual has deep roots in history, as it is said that during the Satya Yuga, King Harishchandra worshiped Lord Rinmukteshwar Mahadev at this temple to seek freedom from his debts. He was required to donate gold equal to the weight of a rhinoceros to Sage Vishwamitra and performed the Peeli Pooja on the banks of the Shipra River, ultimately receiving Lord Shiva's blessings and being freed from his debts. \n\nThe mantra chanted during the pooja is 'Om Rinmukteshwar Mahadevaya Namah,' recited while offering the yellow items, to invoke the deity's blessings and seek liberation from financial burdens.",
      "about_temple_img": "",
      "about_temple": "The Rinmukteshwar Mahadev temple is situated under a tree on the banks of the sacred Shipra River. Near this temple is a Hanuman temple, where devotees also find the idol of Makaradhwaj, Hanumanji's son. Devotees offer yellow worship materials to Lord Rinmukteshwar for debt relief. The temple is ancient and is believed to be the place where King Harishchandra was freed from his debts. Lord Krishna is said to have recited the Shiv Sahasranamavali to Sudama here.",
      "faqs": {
        "I don't know my Gotra, what should I do?": "If you do not know your Gotra, you can write 'Kashyap Gotra' while filling out the form. Alternatively, you can contact our customer support at +919201968470 for assistance.",
        "What is the process after booking the Rinmukteshwar Puja online?": "Once your booking is confirmed, our team will contact you within 24 hours to collect your details, such as your name and Gotra, for the puja. You can also reach out to our team for any additional information regarding the ceremony.",
        "What is the difference between offline and online Rinmukteshwar Puja?": "There is no significant difference between offline and online puja. In both cases, the priest chants your name and Gotra while performing the rituals. Offline puja is done in person at the temple, while online puja allows you to participate and watch the rituals live from the comfort of your home.",
        "Will I receive a recording of the Rinmukteshwar Puja?": "Yes, you will receive a recorded video of the puja, which will be made available to you after the ceremony is completed.",
        "How can I trust Pooja for Rinmukteshwar Puja?": "Our platform is designed to make spiritual experiences accessible and trustworthy. We work with experienced priests who follow traditional rituals to ensure authenticity. Additionally, you will receive live updates, videos, and confirmations to keep you informed at every step.",
        "What offerings will be presented to Lord Shiva during the Rinmukteshwar Puja?": "The offerings during Rinmukteshwar Puja include yellow items such as gram dal, turmeric, yellow flowers, and jaggery. These items are placed on the sacred Shivlinga, accompanied by the chanting of 'Om Rinmukteshwar Mahadevaya Namah' to seek Lord Shiva's blessings.",
        "Can I enroll my entire family for Rinmukteshwar Puja?": "Yes, we offer family packages for Rinmukteshwar Puja. You can contact our customer support at +919201968470 via call or WhatsApp to learn more about the family packages available.",
        "Who can I contact for more information?": "For more information or assistance, please contact our customer support at +919201968470.",
        "What does Avadhoot do apart from ePooja?":"We are a digital platform dedicated to booking tours and packages exclusively in religious cities. Our services go beyond arranging poojas and epoojas, as we offer a variety of services related to temples, such as spiritual guidance, temple visits, and traditional ceremonies. We aim to provide a seamless and enriching spiritual experience by catering to all aspects of your pilgrimage and religious journey."
      },
      "customer_review": [
        {
          "name": "Sanjay Thakur",
          "review": "I’ve done the epooja twice—first alone, and then with my wife. Both times, Prasad was delivered to my address, and the rituals felt genuine and well-performed. I’m satisfied with the experience so far, and it has met my expectations.",
          "rating": 5
        },
        {
          "name": "Meena Reddy",
          "review": " The Prasad and a recording of the pooja were sent to my address, and the priests took the time to correctly pronounce my name and Gotra. It was a smooth and spiritual experience.",
          "rating": 4
        },
        {
          "name": "Sunita Rajan",
          "review": " The Gmeet feature made the experience so much more personal, as I could communicate with the priest during the rituals. It felt like I was actively participating, even though I couldn’t be there in Ujjain. Overall, it was worth it for me, as I got to perform the pooja without traveling."
        }
      ],
      "disclaimer": "Please arrive 30 minutes before the pooja time.",
      price:{
        1:851,
        2:1301,
        3:1901
      }
    }
]

export const getEPoojaMap = () => {
    const poojaMap = new Map();
    Epooja.forEach((pooja) => {
      poojaMap.set(pooja.id, pooja);
    });
    return poojaMap;
};